.content--artist {
  display: block;
  padding: 20px 0;

  @include media(">=sidebardrop") {
    display: table-cell;
    padding: 0px;
    vertical-align: top;
  }
}

.sidebar--artist {
  padding: 20px;
  background: color(primary, base) url('#{$path-images}/bg_dark_wall_2X.jpg') ;
  display: block;
  width:100%;

  @include media(">=sidebardrop") {
    display: table-cell;
    width: 285px;
  }
}


.artist-img {
  border-radius: 50%;
  position: relative;
}

.artist-img--profile {
  border: 10px solid rgba(255, 255, 255, 0.075);
  margin-top: -80px;
}


.artist-meta {
  margin: 0 auto;
  text-align: center;

  @include media(">=sidebardrop") {
    padding: 0 30px;
    text-align: left;
  }
}

.artist-meta p {
  margin: -15px 0 15px;
  color: color(cu-grey, dark);
}

.artist-social {
  list-style-type: none;
  margin: 5px 0;
  padding: 0;

  &:before {
    display:none;

    @include media(">=sidebardrop") {
      display: block;
      content: 'Follow on:';
      color: color(cu-grey, dark);
      font-size: 14px;
      margin: 30px 0 0;
    }
  }
}

.artist-social__item {
  line-height: 2.1rem;

  display: inline;

  &:nth-child(n+3) {
    display: none;
  }

  @include media(">=sidebardrop") {
    display: block;

    &:nth-child(n+3) {
      display: block;
    }
  }
}

.artist-social__link {
  color: color(cu-grey, light);
  background-repeat: no-repeat;
  background-position: 0 3px;
  background-size: 18px;
  font-size: 18px;
  padding: 2px 5px 2px 27px;

  &.icon--facebook:hover {
    background-color: map-get($colors, facebook);
    color: color(cu-grey, light);
  }

  &.icon--twitter:hover {
    background-color: map-get($colors, twitter);
    color: color(cu-grey, light);
  }

  &.icon--instagram:hover {
    background-color: map-get($colors, instagram);
    color: color(cu-grey, light);
  }

  &.icon--vine{
    background-size: 15px;
    background-position: 2px 2px;
  }

  &.icon--vine:hover {
    background-color: map-get($colors, vine);
    color: color(cu-grey, light);
  }
}

// CONTENT AREA

// ANCHOR NAV SHOWS ON DESKTOP ONLY
.nav-artists {
  background: url('../images/sfr/bg_dark_wall_2X.jpg') color(primary, base);
  display: none;

  @include media(">=large") {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
    height: 100px;
    transition: 0.3s height ease;
  }
}

.nav-artists.shrink { // shrink the anchor nav on scroll
  height: 60px;
  transition: height 0.3s
}

.tabs--artist {
  margin: 0;
  padding: 0;
}

.tabs__item--artist {
  display: inline;
  margin: 0 25px;

  & a {
    margin: 0;
    color: white;
    font-size: 19px;
    font-weight: bolder;
    text-transform: uppercase;

    transition: background 0.15s cubic-bezier(0.33, 0.66, 0.66, 1) 0s;
    padding: 2px;

    &:hover {
      background: none repeat scroll 0 0 color('sfr-highlight', dark);
      color: inherit;
      text-decoration:none;
    }
  }
}

// ARTIST ABOUT BLOCK

.artist-about {
  padding: 0 20px;
  margin: 10px 0px;
  position: relative;
  z-index: 0;


  @include media(">sidebardrop") {
    margin: 20px 60px 70px 60px;
    padding: 0;
  }
}

// TRANSPARENT HEADING USED THROUGHOUT PAGE VIEW
.heading-trans {
   display: none;

   @include media(">=1075px") {
     opacity: 0.1;
     z-index: -1;
     color: #fff;
     display: block;
     font-weight: bolder;
     position: absolute;
     text-transform: uppercase;
     font-size: 257px;

   }
 }

.heading-trans--about {
  display: none;

  @include media(">=1075px") {
    display: block;
    font-size: 257px;
    top: 0px;
    left: -40px;
  }

  @include media(">=1200px") {
    font-size: 288px;
    top: 5px;
    left: -40px;
  }
}

.heading-trans--videos{
  display: none;

  @include media(">=1075px") {
    display: block;
    font-size: 257px;
    top: 0px;
    left: 20px;
  }

  @include media(">=1200px") {
    font-size: 288px;
    top: 5px;
    left: 0;
  }
}

.heading-trans--audio{
  display: none;

  @include media(">=1075px") {
    display: block;
    font-size: 257px;
    top: 0px;
    left: -80px;
  }

  @include media(">=1200px") {
    font-size: 288px;
    top: 5px;
    left: -70px;
  }
}

.artist-about-bio {
  padding: 20px 40px;
  margin: 0;
  z-index: 1;
  position: relative;
  width: 100%;
  background-color: white;

  @include media(">=1075px") {
    margin-top: 90px;
  }

  & img {
    display: none;

    @include media(">=large") {
      display: block;
    }
  }
}

.artist-about-bio__heading {
  margin: 10px 0 0;
}

.readmore__link {
  font-weight: 800;
  color: color(sfr-highlight, dark);
  margin-top: 5px;
}

// Merch

.merch--sidebar

// News

.wrapper-carousel--artist {
  margin: 40px 0 60px 0;
}

// Videos
.videos {
  position: relative;
  z-index: 0;
}

.video-playlist {
  width: 100%;
  max-width: 694px;
  margin: 0 auto;
  background: white;


  @include media("<=1025px") {
    max-width: 646px;
  }

  @include media("<=1000px") {
    max-width: 580px;
  }

  @include media("<=sidebardrop") {
    max-width: 646px;
  }

  @include media("<=666px") {
    max-width: 446px;
  }

  @include media("<=466px") {
    max-width: 335px;
  }

  @include media("<=466px") {
    max-width: 335px;
  }

  @include media("<=350px") {
    max-width: 300px;
  }
}

.audio--artist {

  margin: 100px 20px;

  @include media(">=medium") {
    margin: 110px 80px;
    position: relative;
    z-index: 1;
  }

  @include media(">=large") {
    margin: 110px;
    position: relative;
    z-index: 1;
  }

}

//.wrapper--blue {
//  background-image: url('../images/sfr/bg-brush-1.png'), linear-gradient(-150deg, #00C1B6 0%, #136EB5 97%);
//  background-position: bottom;
//  background-repeat: no-repeat;
//  padding: 20px 0 80px;
//
//  & h2 {
//    //text-shadow: -1px -1px 1px #fff, 1px 1px 1px #000;
//    color: white;
//    opacity: 0.2;
//    text-align: center;
//    font-size: 40px;
//    text-transform: uppercase;
//    margin-top: 0;
//  }
//}

