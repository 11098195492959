$gutter: 30px;
$item-width: false; // if need max to be smaller -enter false to fill space

.card {
  flex: 1;
  width: 100%;
  margin: 20px $gutter/2;
  z-index: 1;

  @if $item-width {
    max-width: $item-width;
  }

  &:hover {
    @include shadow(4);

    .card-text {
      background: white;
    }
  }

  @include media("<=840px") {
    width: 300px;

    &:nth-child(2n) {
      display: none;
    }
  }

    @include media("<=600px") {
      max-width: 80%;
      align-self: center;
      margin: 15px auto;
      flex: 1 0 auto;
    }

}

.card__link {
  flex: 1;
  display: flex;
  text-align: center;
  text-decoration: none;
  color: #000;
  height: 155px;
}


.card__media {
  flex: 1.25;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 160px;
}

.card-text {
  flex: 1.25;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #efefef;

  @include media("<=large") {
    padding: 1rem;
  }
}

.card-text__heading {
  font-size: 18px;
  line-height: 19px;
  font-weight: 800;
  text-transform: uppercase;
  //margin-bottom: 2rem;

  @include media("<=large") {
    font-size: 16px;
  }

  @include media("<=medium") {
    font-size: 15px;
  }
}

.card-text__content {
  margin-bottom: 1rem;
}

/* remove the margin from the last item */
.card-text > *:last-child {
  margin: 0;
}

.card-footer {
  background: #333;
  height: 40px;
  display: flex;
  //justify-content: space-between;
  align-items: center;
}

.card-quick-links__link {
  display: inline-block;
  text-indent: -9999px !important;
}


@import "card-news";
@import "card-product";
