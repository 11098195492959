.sidebar {
  color: color(cu-grey, light);
}



.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 15px;
}
