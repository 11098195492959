.listing-events {
  padding: 0 20px;
  margin: 10px 0px;
  position: relative;
  z-index: 0;

  @include media(">=sidebardrop") {
    margin: 20px 60px 70px 60px;
    padding: 0;
  }
}

.heading-trans--events {
  display: none;

  @include media(">=1075px") {
    display: block;
    font-size: 257px;
    top: -10px;
    left: -45px;
  }
}

// Event block heading with artist profile img and name
.listing-events__header {
  background: white;
  margin: 40px auto 5px auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  & h2 {
    font-size: 26px;
    margin: 0 20px;
    flex: 1;
    line-height: 1em;

    @include media(">=medium") {
      font-size: 36px;
      line-height: 0.8em;
    }
  }

  & span {
    font-weight: 400;
    font-size: 17px;
    text-transform: capitalize;

    @include media(">=medium") {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 20px;
    }
  }
}

// MOVE THIS? Artist small img and event count

.artist-img--sm {
  border: 10px solid rgba(255, 255, 255, 0.075);

}

.artist-sm {
  position: relative;
  z-index: 0;
  flex: 1;
  max-width: 125px;
}

.artist-sm:after{
  content: attr(data-badge);
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 15px;
  background: color(sfr-highlight, base);
  color: white;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  z-index: 10;
  font-weight: 700;
}

// Event item block container
.event {
  background: white;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 auto 5px auto;
  z-index: 1;
}

.event__date {
  flex: 1 0 0;
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
  max-width: 120px;
}


.event__date--day {
  align-self: center;
  font-size: 60px;
  font-weight: 800;
  text-align: center;
  padding: 0;
  line-height: 0.95em;
}

.event__date--month {;
  font-size: 31px;
  font-weight: 400;
  padding: 0;
  line-height: 0.85em;
  color: grey;
}

.event__meta {
  flex: 1 0 0;
  margin-top: 10px;
}
.event__meta h3 {
  font-size: 20px;
  margin-bottom: 4px;

  @include media(">=medium") {
    font-size: 24px;
    margin-bottom: 5px;
  }
}

.event__meta p {
  font-size: 15px;
  margin-top: 5px;
  line-height: 1.6em;

  & span:nth-of-type(n+2), .text-sep {
    margin-left: 5px;
    padding-left: 7px;
    border-left: 1px solid color(cu-grey, dark)
  }
}

.event__meta a{
    color: color(sfr-highlight, dark);
  font-weight: 700;

  &:hover {
    color: color(primary, base);
  }
}


.event-buttons {
  display: none;

  @include media(">=960px") {
  flex:0.5;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  border-left: solid 1px #CABDAA;
  text-align: center;
  }
}

.event-buttons__item {
  flex:1;
  display:flex;
  flex-direction:column;
  justify-content:center;

}

.event-buttons__item:hover {
  background: color(sfr-highlight, dark);
  color: white;
}

.event-buttons__item--tickets{
  border-bottom: solid 1px #CABDAA;
}