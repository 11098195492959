$gutter: 30px;

.card-news {
  flex: 1 0 25%;
  width: 100%;
  max-width: 375px;
  margin: 20px $gutter/2;

  &:hover {
    @include shadow(4);

    .card-news-text {
      background: white;
    }
  }

  @include media("<=900px") {
    width: 300px;
    // third card is hidden
    &:nth-child(2n) {
      display: none;
    }
  }

  @include media("<=600px") {
    max-width: 80%;
    align-self: center;
    margin: 15px auto;
    flex: 1 0 auto;
  }
}

.card-news-media {
  flex: 1;
  display: flex;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  color: #000;
  min-height: 150px; // needed for firefox ??
}


.card-news-media__img {
  flex: 1;
  vertical-align: top;
  min-width: 160px;
  max-height: 150px;
}

.card-news-text {
  flex: 1.25;
max-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #efefef;

  @include media("<=large") {
    padding: 0;
    font-size: 16px;
  }

  @include media("<=medium") {
    font-size: 15px;
  }
}

.card-news-text__heading {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 20px;
  //margin-bottom: 2rem;

  @include media("<=large") {
    font-size: 16px;
  }

  @include media("<=medium") {
    font-size: 15px;
  }
}

.card-news-text__content {
  margin-bottom: 1rem;
}

/* remove the margin from the last item */
.card-news-text > *:last-child {
  margin: 0;
}

.card-news-footer {
  background: color(primary, base);
  height: 40px;
  display: flex;
  //justify-content: space-between;
  align-items: center;
}

.card-news-footer a {
  background-size: 20px;
  width: 25px;
  height: 40px;
  flex: 1;

  &:nth-of-type(1) {
    background-position: 20px;
    width: 25px;
    height: 40px;
    flex: 2.5;
  }

  &:nth-of-type(2) {
    flex: 0.5;
  }

  &:hover {
    background-color: color('sfr-highlight', base);
  }
}

