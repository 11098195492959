$gutter: 40px;
$item-width: false; // if need max to be smaller -enter false to fill space

.card-product {
  position: relative;
  flex: 1 0 0;
  min-width: 0; /* for Firefox and future Chrome etc. */
  width: 100%;
  margin: 20px $gutter/2;

  @if $item-width {
    max-width: $item-width;
  }

  &:hover .card-product-media{
    @include shadow(4);
  }

  @include media("<=medium") {
    //width: 150px;
    //margin: 0 30px;

  }

  @include media("<=600px") {
    min-width: 40%;
    margin: 20px 30/2;
    //align-self: center;
    //margin: 15px auto;
    flex: 1 0 36%;
  }

}

.card-product-media__meta {
  color: white;
  display: block;
  font-size: 14px;
  line-height: 18px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: color(sfr-highlight, dark);
  padding: 2px 5px;
}

.card-product-media__img {
  vertical-align: top;
  max-height: 260px;
}

.card-product-media__img:after  {
  content: "HOT";
  display: block;
  font-size: 14px;
  font-weight: bolder;
  line-height: 1;
  position: absolute;
  top: 5px;
}


.card-product__link {
  flex: 1;
  display: flex;
}


.card-product-name {
  flex: 1.25;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #efefef;

  @include media("<=large") {
    padding: 1rem;
  }
}

.card-product-text {
  text-align: center;
}

.card-product-text__title {
  font-size: 17px;
  line-height: 19px;
  font-weight: 600;
  margin: 8px 0 4px;

  @include media("<=large") {
    font-size: 16px;
  }

  @include media("<=medium") {
    font-size: 15px;
  }

}

.card-product-text__content {
  margin-bottom: 1rem;
}

/* remove the margin from the last item */
.card-product-text > *:last-child {
  margin: 0;
}

.card-product-footer {
  background: #333;
  height: 40px;
  display: flex;
  //justify-content: space-between;
  align-items: center;
}

.card-product-footer a {
  background: svg($path-svgs+"sharing.svg", "[fill]: white") no-repeat center;
  background-size: 20px;
  //width: 25px;
  height: 40px;
  flex: 0.75;

  &:nth-of-type(1) {
    background: svg($path-svgs+"ellipsis.svg", "[fill]: white") no-repeat center;
    background-size: 20px;
    width: 25px;
    //height: 40px;
    //flex: 1;
  }

  &:nth-of-type(3) {
    flex: 2.5;
    background-position: calc(100% - 15px) center;

  }

  &:hover {
    background-color: color('sfr-highlight', base);
  }

  @include media("<=medium") {
    &:nth-of-type(2) {
      display: none;
    }
  }
}

