footer {
  background: color(primary, base) url('https://cdn.shopify.com/s/files/1/0133/8422/t/7/assets/bg_dark_wall_2X.jpg?15728505310528581281') ;
  padding: 20px 0 40px 0;
}

.footer-nav {
  flex: 1 0 0;
  margin: 20px;
  padding: 0;

  &:nth-child(1) {
    display: none;
  }

  &:nth-child(2) {
    display: none;
  }


  &:first-child {
    margin-left: 40px;
    flex: 1.5 0 0;
  }

  &:last-child {
    margin-right: 40px;
    flex: 2 0 0;
  }


  @include media(">=medium") {
    margin: 20px 40px;
    &:nth-child(1) {
      display: block;
    }
  }

  @include media(">=840px") {
    &:nth-child(2) {
      display: block;
    }

    @include media(">=900px") {
      &:first-child {
        flex: 1 0 0;
      }
    }

    @include media(">=large") {
      &:last-child {
        flex: 1 0 0;
      }
    }

  }
}
.footer-nav__heading {
  font-size: 16px;
  color: color(cu-grey, light);
  margin: 10px 0;

  &:not(:first-child) {
    margin-top: 40px;
  }
}

.footer-nav__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-width: 160px;
}

.footer-nav__list li {
  margin-bottom: 10px;

  @include media("<=medium") {
    margin-bottom: 25px;
  }
}

.footer-nav__list a {
  color: color(cu-grey, dark)
}

.footer-nav__list a:hover {
  color: color(sfr-highlight, light);
}

.footer-attribution {
  //margin: 0 80px;
  text-align: center;
  color: color(cu-grey, dark);
  & a {
    color: color(cu-grey, light);
  }

  & a:hover {
    color: color(sfr-highlight, light);
  }
}
.footer-attribution__copyright {
  background-image: url('https://cdn.shopify.com/s/files/1/0133/8422/t/7/assets/logo-sfr-2x.gif?15728505310528581281');
  background-image: url('https://cdn.shopify.com/s/files/1/0133/8422/t/7/assets/logo-sfr-white.png?15728505310528581281');
  background-repeat: no-repeat;
  background-size: 329px 140px;
  background-size: 148px 135px;
  background-position: center top;
  display: inline-block;
  color: color(cu-grey, dark);
  padding: 155px 80px 0;
  margin-bottom: 5px;

  @include media("<=medium") {
    padding: 155px 20px 0;
  }
}

.footer-attribution__props {
  margin-top: 0;
}