$gutter: 30px;

.card-artist {
  flex: 1 0 auto;
  max-width: 368px;
  min-width: 368px;
  margin: 0 15px 20px;
  overflow: hidden;

  &:hover {
    @include shadow(4);

    .card-news-text {
      background: white;
    }
  }


  @include media("<=1193px") {
    margin: 0 auto 25px;
  }
}


