.banner {
  margin-bottom: 20px;
}


.banner--bg {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background: no-repeat center center;

  @include media(">=medium") {
    height: 400px;
  }
}

.banner-heading {
  color: white;
  font-size: 38px;
  text-transform: uppercase;
  text-shadow: 1px 1px 0 black;
  line-height: 1em;
  text-align: center;
  padding: 0 40px;

  @include media(">=medium") {
    font-size: 60px;
  }
}

.banner--about {
  margin-bottom: 0;
}