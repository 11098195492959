/*

    Project mixins
    Docs - http://...just do it

    @since Strtr WP 1.0

*/

// needs toc

// Event wrapper - @author Harry Roberts
// @param {Bool} $self [false] - Whether or not to include current selector
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

// Standard link pseudo-classes

@mixin linx ($link, $visit, $hover, $active) {
  a {
    color: $link;
    &:visited {
      color: $visit;
    }
    &:hover {
      color: $hover;
    }
    &:active {
      color: $active;
    }
  }
}

// Visually hide an element but not from screen readers
%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

// retrieve color from $colors map ie. `color(00_base, primary)`
@function color($color-name, $color-variant:null) {
  // color variant is optional
  @if ($color-variant != null) {
    // map inception
    @return map-get(map-get($colors, $color-name), $color-variant);
  } @else {
    @return map-get($colors, $color-name);
  }
}

/* MIXINS FOR MATERIAL DESIGN SHADOWS */

/// Computes a top-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  $blur: nth(1.5 3 10 14 19, $depth) * 4px;
  $color: rgba(black, nth(.12 .16 .19 .25 .30, $depth));

  @return 0 $primary-offset $blur $color;
}

/// Computes a bottom-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 4px;
  $color: rgba(black, nth(.24 .23 .23 .22 .22, $depth));

  @return 0 $primary-offset $blur $color;
}

/// Gives a card depth effect.
/// @param {Number} $depth - depth level (between 1 and 5)
/// @link http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality Google Design
/// @requires {function} top-shadow
/// @requires {function} bottom-shadow
@mixin shadow($depth) {
  @if $depth < 1 {
    box-shadow: none;
  } @else if $depth > 5 {
    @warn "Invalid $depth `#{$depth}` for mixin `shadow`.";
  } @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth);
  }
}

/* END MIXINS FOR MATERIAL DESIGN SHADOWS */

$gutter: 30px;
$item-width: false; // if need max to be smaller -enter false to fill space
$space-between: false; // used with a set width
$max-width: 1200px;

@mixin grid-flex ($justify: space-between){
  @if $justify-content { justify-content: $justify; }
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

/*

   This file contains all application-wide  functions.

    @since WP Strtr 1.0

*/

/// Native `url(..)` function wrapper
/// @param {String} $00_base - 00_base URL for the asset
/// @param {String} $type - asset type folder (e.g. `fonts/`)
/// @param {String} $path - asset path
/// @return {Url}
@function asset($base, $type, $path) {
  @return url($base + $type + $path);
}

/// Returns URL to an image based on its path
/// @param {String} $path - image path
/// @param {String} $00_base [$00_base-url] - 00_base URL
/// @return {Url}
/// @require $00_base-url
@function image($path, $base: $base-url) {
  @return asset($base, 'images/', $path);
}

/// Returns URL to a font based on its path
/// @param {String} $path - font path
/// @param {String} $00_base [$00_base-url] - 00_base URL
/// @return {Url}
/// @require $00_base-url
@function font($path, $base: $base-url) {
  @return asset($base, 'fonts/', $path);
}

// Slightly lighten a colour
@function tint($color, $percent){
  @return mix(white, $color, $percent);
}

// Slightly darken a colour
@function shade($color, $percent){
  @return mix(black, $color, $percent);
}

// tool belt mixins via https://github.com/dmtintner/UtilityBelt
// tooltips
@mixin hui_tooltip($content: attr(data-tooltip), $direction: top) {
  position: relative;
  &:before, &:after {
    display: none;
    z-index: 98;
  }
  &:hover {
    &:after { // for text bubble
      content: $content;
      display: block;
      position: absolute;
      height: 12px; // (makes total height including padding 22px)
      padding: 6px;
      font-size: 12px;
      white-space: nowrap;
      color: #fff;
      @include text-shadow(1px 1px #000);
      background-color: #222;
    }
    @if ($direction == 'top'){
      &:before {
        @include triangle(down, 6px, #222);
        top: -6px; margin-top: 0;
        left: 47%;
      }
      &:after {
        top: -28px;
        left: 47%; margin-left: -20px;
      }
    }
    @else if ($direction == 'bottom'){
      &:before {
        @include triangle(up, 6px, #222);
        top: auto; margin-top: 0;
        bottom: -6px;
        left: 47%;
      }
      &:after {
        bottom: -28px;
        left: 47%; margin-left: -20px;
      }
    }
  }
}


//truncate text
@mixin truncateText($overflow: ellipsis){
  overflow: hidden;
  white-space: nowrap;
  text-overflow: $overflow; // values are: clip, ellipsis, or a string
}
