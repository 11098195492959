/*

    Strtr v1.0
    SASS/Twig starter framework
    http://dannybrown.ca
    Danny B
    2016

    Notes:
    Strtr follows the BEM method for writing & organising CSS.
    Docs: http://outlinecss.co.uk/get-started.html#buttons

*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Normalize
# Typography
# Elements
# Forms
# Navigation
	## Links
	## Menus
# Accessibility
# Alignments
# Clearings
# Widgets
# Content
    ## Posts and views
	## Asides
	## Comments
# Infinite scroll
# Media
	## Captions
	## Galleries


// Name:            Article
// Description:     Defines styles for articles within your page
//
// Component:       `uk-article`
//
// Sub-objects:     `uk-article-title`
//                  `uk-article-meta`
//                  `uk-article-lead`
//                  `uk-article-divider`
//
// Markup:
//
// ========================================================================


/* Variables
--------------------------------------------------------------
Import project-wide variables, mixins and breakpoints
-------------------------------------------------------------*/
@import '00_base/01-mixins';
@import '00_base/00-variables';
@import './bower_components/include-media/dist/_include-media.scss';

/* CRITICAL CSS
-----------------------------------------------------------*/
//$critical-css: false;
//@if $critical-css != true {
//}
//@import "01_critical/critical";

/* Components
-----------------------------------------------------------------------------
Components include singular, independent, reusable modular components.
Components combine together to form 03_blocks.
Often, these are common elements like an .alert, .icon, .button, or .badge.
Components can have a state via modifiers (for eg. .button--disabled)
---------------------------------------------------------------------------*/
@import '02_atoms/01-icons/_icons.scss';
@import '02_atoms/02-cards/_card.scss';
@import '02_atoms/02-cards/_card-media.scss';
@import '02_atoms/02-cards/_card-artist.scss';
@import '02_atoms/06-buttons/_buttons.scss';

/* Blocks
----------------------------------------------------------------------------------------
Blocks are patterns comprised of multiple objects. This can include Atoms.
The Blocks dir is reserved for objects that have children, whom they effect.
For eg, .hero-header could contain the .hero-header__title & .hero-header__description.
Blocks can also contain Atoms within them, and may elaborate  Atom styles.
Like Atoms, Blocks can also have modifier states.
---------------------------------------------------------------------------------------*/
@import "03_blocks/01-header/_02-banners";
@import "03_blocks/04-media/_02-img-slider.scss";
@import '03_blocks/03-listings-grids/grid';
@import '03_blocks/03-listings-grids/_merch-tabs';
@import '03_blocks/03-listings-grids/_04-events.scss';
@import "03_blocks/04-media/01-carousel-news";
@import '03_blocks/06-sidebars/_sidebar.scss';
@import "03_blocks/08-footer/newsletter-social";
@import "03_blocks/08-footer/footer-sitemap";


/* Views
-------------------------------------------------------------------
This dir exists for styles specific to a single view
impacting multiple 03_blocks within them.
Definitions here are so specific that it is nonsensical or
even destructive to try to abstract them into a pattern.
Storing some styles here can also help protect Atoms & Blocks
from having so many modified states that they become meaningless.
-------------------------------------------------------------------*/
@import 'views/_single.scss';
@import 'views/_single-artist.scss';
//@import 'patterns/02_atoms/04-layouts/_01-holygrail.scss';


/* VENDOR STYLES
------------------------------*/

// Remodal - set in config.scss


  @import "./bower_components/remodal/dist/remodal.scss";
  // overlay wrapper
  $remodal-overlay-color: rgba(43, 46, 56, 0.9);
  $remodal-wrapper-padding: 10px;
  // modal dialogue
  $remodal-width: 80%;
  $remodal-height: 100%;
  $remodal-padding: 35px;
  $remodal-margin: 0 0 10px 0;
  $remodal-bg-color: white;
  $remodal-text-color: #2b2e38;
  $remodal-vertical-align: middle;
  $remodal-close: left; // close button on right or left
  $remodal-slidein: bottom; //slidein animations from: top, bottom, left, right - leave blank for fadein*/
  $remodal-slideout: bottom; //slidein animations from: top, bottom, left, right - leave blank for fadein*/
  @import "vendor/remodal/remodal-strtr-theme";
  @import "vendor/tooltip/tooltip";
  //@import "vendor/tooltipster/tooltipster";
  //@import "vendor/tether-drop/_drop-theme-basic.min.scss";
  //@import "vendor/tether-drop/_drop-theme-arrows.min.scss";
  //@import "vendor/tether-drop/_drop-theme-arrows-bounce-dark.scss";
  //@import "vendor/tether-drop/tip-share";

  @import "03_blocks/05-off-canvas/_00-menu-mobile";
  // tabs


.fixed {
  position: fixed !important;
}

.feature__link img {
  display: block;
}

