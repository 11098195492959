.a-anchor {
  display: block;
  height: 115px; //offset sticky nav
  margin-top: -115px;  //offset sticky nav
  visibility: hidden;
}

.a-anchor--news {
  height: 20px;
  margin-top: -20px;
}

.grid {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
  width: 100%;
  margin: 0 auto 40px 0;
  padding: 0;
  position: relative;
  justify-content: center;
  //justify-content: space-between;
}

.grid--1 {
  padding: 20px 110px;
}

$space-between: false; // used with a set width
$max-width: 1200px;

@import "grid-media";
