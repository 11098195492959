.button--imperfect {
    align-self: center;
    background: transparent;
    padding: 0.6rem 0.8rem;
    margin: 0 1rem;
    transition: all .5s ease;
    color: #41403E;
    font-size: 15px;
    letter-spacing: 1px;
    outline: none;
    //box-shadow: 20px 38px 34px -26px hsla(0,0%,0%,.2);
    border-radius: 255px 15px 0px 15px/15px 225px 15px 255px;
    border:solid 2px #41403E;
    display: inline-block;

  &:hover {
    cursor: pointer;
  }
}

.button--blue {
  color: white;
  background: color(sfr-highlight, dark);

  &:hover {
    background: white;
    color: color(sfr-highlight, dark);
  }
}

.button--brown {
  color: white;
  background: color('sfr-brown', dark);

  &:hover {
    background: color(sfr-highlight, dark);
    color: white;
  }
}
