
.wrapper-carousel--news {
  width: 100%;
  z-index: 0;
  position: relative;
  //background-image: url('../images/sfr/bg-brush-1.png'), linear-gradient(-150deg, #00C1B6 0%, #136EB5 97%);
  background-image: url('../images/sfr/bg-brush-1.png');
  //background-image: url('../images/sfr/bg-brush-1.png');
  background-color: #2C859D;
  //background-color: #497285;
  //
  //background-color: #5D5D5D;
  //background-color: #9D9382;


  background-position: bottom;
  background-repeat: no-repeat;
  padding: 20px 0;
  overflow: hidden;


  //@if map-get($nav, position) == fixed {
  //  margin-top: map-get($nav, shrink-height);
  //
  //  @include media(">medium") {
  //    margin-top: map-get($nav, height);
  //    padding: 40px 0;
  //  }
  //}
}



.wrapper-carousel--news:after {
  display:none;

  @include media(">=900px") {
    margin: 0 auto;
    opacity: 0.1;
    z-index: 0;
    left: 0;
    right: 0;
    color: #333;
    content: attr(data-bg-text);
    display: block;
    font-size: 33vw;
    font-weight: bolder;
    line-height: 1;
    position: absolute;
    top: 305px;
    left: 68px;
  }

  @include media(">=large") {
    left: 88px;
    font-size: 370px;
    top: 300px;
  }
}

.wrapper-carousel--newssidebar {
  margin-top: 40px;
}
.wrapper-carousel--newssidebar:after {
      display: none;
}

.carousel {
  z-index: 1;
  margin-bottom: 30px;
  position: relative;
}

.carousel button.flickity-prev-next-button {
  opacity: 0;
  height: 100%;
  border-radius: 0;
  width: 14%;
  margin:0;
  padding: 0;
  right: 0;

  @include media("<=large") {
    width: 11%;
  }

  @include media("<=medium") {
    width: 23%;
  }

  @include media("<=650px") {
    width: 15%;
  }
}

button.flickity-prev-next-button:active {
  opacity: 0;
}

.flickity-prev-next-button.next {
  right: 0;
}

.flickity-prev-next-button.previous {
  left: 0;
}

.next {
  position: absolute;
  right: 0;
}



.carousel-item__link {
  display: flex;
  height: 350px;
  width: 66%;
  text-align: center;
  text-decoration: none;
  color: #000;
  opacity: 0.3;
  transition: opacity 1.25s ease-in-out;
  margin-right: 50px;
  margin-bottom: 15px;
  background: white;
  counter-increment: carousel__item;
  @include shadow(2);


 @include media("<=large") {
   margin-right: 25px;
   width: 76%;
 }

  @include media("<=medium") {
    margin-right: 15px;
    max-width: 350px;
    width: 100%;
    flex-direction: column;
    height: 525px;
  }

  @include media("<=650px") {
    width: 77%;
  }
}

a.carousel-item__link:hover{
  @include shadow(3);
}

a.carousel-item__link:hover > .carousel-item-text{
  background: white;
}

a.carousel-item__link:hover .button--imperfect{
  background: color('sfr-brown', light);

  &:hover {
    cursor: pointer;
  }
}

.carousel-item__link.is-selected {
  background: white;
  opacity: 1;
}

.carousel-item__link__media{
  flex: 1;
  //min-width:350px;
  height: auto;

  @include media("<=medium") {
    max-height: 300px;
  }
}

.carousel-item-text {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #efefef;
  word-wrap: break-word;

  @include media("<=medium") {
    padding: 0 10px;
  }

  & button {
    display: none;

    @include media(">=880px") {
      display: block;
    }
  }
}

.carousel-item-text--sidebar button { // hide on views with sidebars
  display: none;
}

// remove the margin from the last item
.carousel-item-text > *:last-child {
  margin: 0;
}

.carousel-item-text__heading {
  font-size: 18px;
  margin-bottom: 10px;
  padding: 0px 10px;
  text-transform: uppercase;
  font-weight: 800;

  @include media(">=large") {
    //background: url(http://localhost:8890/wordpress/wp-content/themes/build/assets/svgs/smudge.svg) center no-repeat;
    //background-size: 356px;
    font-size: 22px;
    padding: 10px 20px 0 20px;
    color: black;
  }
}

.carousel-item-text__content {
  margin-bottom: 20px;
  padding: 0 40px;

  @include media("<=large") {
    padding: 0 20px;
    margin-top: 0;
  }
}

