.menu-cancel {
  position: fixed;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  bottom: -55px;
  border-radius: 6px;
  background: white;
  border: none;
  border-spacing: 0;
  color: color(sfr-highlight, base);
  font-size: 1.15rem;
  font-weight: 800;
  list-style: none outside none;
  padding: 0.75rem 0;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  border-radius: 15px;
}

.remodal.for-mobile-menu {
  width: 90%;
  height: auto;
  max-width: 800px;
  border-radius: 15px;
  vertical-align: bottom;
  padding: 0;
  background: #fff;
  margin-top: 0px;
  margin-bottom: 50px;

}
.remodal-bg.for-mobile-menu.remodal-is-opening,
.remodal-bg.for-mobile-menu.remodal-is-opened {
  filter: none;
}


.remodal-overlay.for-mobile-menu {
  //background-color: yellow;
}


@keyframes remodal-closing-keyframes{
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    transform: translateY(100%);
    opacity: 1;
  }
}


.nav-mobile__heading {
  text-transform: uppercase;
  padding: 0.5rem;
  margin: 0;
  color: #7B7B7B;
  font-size: 0.6rem;
  border-bottom: 1px solid color(cu-grey, base);
}

.nav-mobile-list {
  margin: 0;
  padding: 0;
}

.nav-mobile-list li:last-child {
  border-bottom: none;
}

.nav-mobile-list__item  {
  border-top: 1px solid rgba(255, 255, 255, .075);
  list-style: none;
  margin-bottom: 0;
  position: relative;
  border-bottom: 1px solid color(cu-grey, base);
}

.nav-mobile-list__item a {
  display: inline-block;
  color: color(primary, base);
  font-size: 1.15rem;
  font-weight: 600;
  padding: 0.75rem 0;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: color(sfr-highlight, base);
    color: white;
  }
}

.nav-mobile ul ul {
  margin: 0;
  display: none;
}

.nav-mobile  ul ul li {
  border-top: none;
  padding-left: 1rem;
  border-bottom: none;
}


.nav-mobile ul li li a {
  color: #7B7B7B;
  font-size: 0.8rem;
}
