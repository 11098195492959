$gutter: 30px;
$item-width: false; // if need max to be smaller -enter false to fill space

.card-media {
  background-color: black;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  min-width: 0; /* for Firefox and future Chrome etc. */
  width: 100%;
  margin: 20px $gutter;

  &:first-child {
    margin-left: 60px;
  }

  &:last-child {
    margin-right: 60px;
  }

  @if $item-width {
    max-width: $item-width;
  }

  @include media("<=medium") {
      flex: 1 0 50%;

    &:first-child {
      margin-left: 30px;
    }

    &:last-child {
      margin-right: 30px;
    }
  }

  //@include media("<=600px") {
  //  min-width: 40%;
  //  margin: 20px 30/2;
  //  //align-self: center;
  //  //margin: 15px auto;
  //  flex: 1 0 36%;
  //}
}

.card-media__heading {
  text-transform: uppercase;
  background-color: color(sfr-brown, light);
  margin: 0;
  padding: 15px 0;
}

.card-media-link {
  position: relative;
  height: auto;
  display: block;
  z-index: 0;

  &:hover .card-media-link__img {
    opacity: 1;
  }
}

.card-media-link__overlay {
  position: absolute;
  background: svg($path-svgs+"play.svg", "[fill]: white") no-repeat center;
  background-size: 60px;
  opacity: 0.7;
  height: 100%;
  width: 100%;
  z-index: 1;

}

.card-media-link__img {
  vertical-align: bottom;
  opacity: 0.6;
  background: black;
  position: relative;
  z-index: 0;
}

.card-media-text {
  background-color: white;
  padding: 20px 40px;
  flex-grow: 1;
  margin-top: auto;
  //height: 300px;
}

.card-media-text a, .card-media-text__meta a {
  color: color(sfr-highlight, dark);
  font-weight: 800;

  &:hover {
    background: color(sfr-highlight, dark);
    color: white;
  }
}


.card-media-text__heading {
  margin: 5px 0;
}

.card-media-footer {
  background: color(primary, base);
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-media-footer a {
  background-size: 20px;
  width: 60px;
  height: 40px;
  align-self: baseline;
  //flex: 0.25;

  &:nth-of-type(1) {
    background-position: 20px center;

  }

  &:hover {
    background-color: color('sfr-highlight', base);
  }

  @include media("<=medium") {
    &:nth-of-type(2) {
      display: none;
    }
  }
}