.img-slider {
  position: relative;
}
.img-slider-list {
  height: 350px;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.img-slider-list__item {
  display: inline-block;
  width: 350px;
  height: 350px;
  text-align: center;
  background-color: black;
  position: relative;

  &:hover img {
    opacity: 0.9;
  }

  &:hover .img-slider-header:before  {
      content : "";
      position: absolute;
      left    : 0;
      right  : 0;
    margin-left: auto;
    margin-right: auto;
      bottom: 20px;
      height  : 1px;
      width   : 40px;  /* or 100px */
      border-bottom:1px solid color(sfr-highlight, base);
  }
}

.img-slider__image {
  opacity: 0.55;

}

.img-slider-header {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.80) 0%, rgba(0,0,0,0.65) 38%, rgba(0,0,0,0) 90%);
  background: -moz-linear-gradient(bottom, rgba(0,0,0,0.80) 0%, rgba(0,0,0,0.65) 38%, rgba(0,0,0,0) 90%);
  padding-bottom: 7px;
}

;

.img-slider-header__heading {
  color: white;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
}

.img-slider-header__subheading {
  color: color(cu-grey, dark);
  margin-top: 5px;
}