// default post content wrapper
article {
  font-size: 1.2rem;
  line-height: 1.6em;
  z-index: 0;
}

.content--post__body a {
  //color: #658e9c;
  //color: #4d7ea8;
  //color: #517fa4;
  //color: #784f41;
  //color: #7c3238;
  color: color(sfr-highlight, dark);
  text-decoration: none;
  font-weight: 700;

  &:hover {
    color: #fff;
    background: #7c3238;
    text-decoration: none;
  }
}

.content__heading--post {
  font-size: 44px;
  line-height: 2.6rem;
  margin-top: 4px;
}

.content--post__byline a {
  color: #028D92;

  &:hover {
    text-decoration: underline;
  }
}

// blog posts
.content--post {
  padding: 0;
  position: relative;
  display: inherit;

  @include media(">=medium") {
    padding: 40px;
  }

  @include media(">=large") {
    padding: 40px 80px;
  }
}

@include media("<=medium") {

  .content--post__header {
    display: block;
    background-color: black;
    height: auto;
    min-height: 375px;
    width: 100%;
    position:relative;
    overflow: hidden;
  }

  .content--post__header img {
    position: absolute;
    display: block;
    opacity: 0.45;
    overflow: hidden;
    max-width: 100%;
    vertical-align: bottom;
  }

  .content__heading--post {
    z-index: 1;
    position: absolute;
    color: white;
    padding: 0 30px;
    text-align: center;
    top: 50%;
    margin-top: -50px;
    line-height: 1.15em;
    font-size: 33px;
    width: 100%;
  }

  .content--post__header span {
    z-index: 1;
    position: absolute;
    height: auto;
    color: color(cu-grey, dark);
    top: 30%;
    margin-top: -50px;
    text-align: center;
    width: 100%;
    display: inline-block;
  }
}



.content--post__body, .content--post__comments {
  background-color: white;
  padding: 30px;

  @include media(">=medium") {
    padding: 20px 50px;
  }
}

.sidebar--post a {
  color: color(cu-grey, dark);
  text-decoration: none;

  &:hover {
    color: color(sfr-highlight, base)
  }
}

.sidebar--post ul {
  margin-bottom: 40px;
}