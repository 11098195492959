.merch {
  position: relative;
  margin: 0 auto 40px auto;
  z-index: 0;
  min-height: 390px;
  text-align: center;
  overflow: hidden;
}


// Merch background header title full screen
.merch:before {

   display:none;

   @include media(">=900px") {
     margin: 0 auto;
     opacity: 0.2;
     z-index: 0;
     left: 20px;
     color: #fff;
     content: attr(data-bg-text);
     display: block;
     font-size: 270px;
     font-weight: bolder;
     line-height: 1;
     position: absolute;
     top: -30px;
   }

   @include media(">=960px") {
     left: 50px;
     top: -50px;
     font-size: 290px;
   }

   @include media(">=1120px") {
     left: 110px;
     font-size: 313px;
     top: -45px;
   }
 }

.merch--sidebar:before {

  //display:none;

  @include media(">=medium") {
    left: 10px;
    top: -50px;
    font-size: 230px;
  }

  @include media(">=1173px") {
    left: 20px;
    font-size: 280px;
    top: -45px;
  }
}

.tab-wrapper {
  z-index: 1;
  position: relative;
}
.tab-wrapper > * {
  display: none;
}

.tab-wrapper > .active {
  display: block;
}



.tabs {
  text-align: center;
  margin: 10px 20px  20px;
  z-index: 0;
  position: relative;
  padding: 0;
}

.tabs a {
  cursor: pointer;
}

.tabs__item {
  display: inline;
  margin: 0 25px;
  line-height: 2.6em;


  & a {
    color: color('primary', base);
    font-size: 18px;
    font-weight: bolder;
    text-transform: uppercase;
    transition: background 0.15s cubic-bezier(0.33, 0.66, 0.66, 1) 0s;
    padding: 2px;

    @include media(">=medium") {
      font-size: 18px;
    }

    &:hover {
      background: none repeat scroll 0 0 color('sfr-highlight', dark);
      color: inherit;
      text-decoration:none;
    }
  }
}

.tabs__item.active a {
  box-shadow: inset 0 -4px 0 color('sfr-highlight', dark);
}