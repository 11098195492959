/*

    Project global variables
    Docs - http://...just do it

    @since Strtr WP 1.0

*/

// Reminder: to access a nested map, you simply nest the map-get:
// color: map-get(map-get($colors, primary), 00_base);
// h1 { color: color(primary, 00_base);}

@import '01-mixins';
// Project Paths
$path-images: '/wp-content/themes/build/assets/images/sfr';
$path-svgs: './src/assets/svgs/';
$path-bower: './bower-components/';
$path-patterns: '';

$colors: (
        primary: (
                base:   #333,
                light:  lighten(#333, 15%),
                dark:   darken(#333, 15%),
                trans:  transparentize(#533355, 0.5)
        ),
        secondary: (
                base:   #555,
                light:  lighten(#555, 15%),
                dark:   darken(#555, 15%),
                trans:  transparentize(#555, 0.5)
        ),
        sfr-highlight: (
                base:   #0ACCD3,
                light:  lighten(#0ACCD3, 15%),
                dark:   darken(#0ACCD3, 5%),
                trans:  transparentize(#0ACCD3, 0.5)
        ),
        sfr-brown: (
                base:   #B4A288,
                light:  lighten(#B4A288, 11%),
                dark:   darken(#B4A288, 10%),
                trans:  transparentize(#B4A288, 0.5)
        ),

        type:  #191919,
        type-light: #B8B4B4,
        // carleton
        cu-red: #cf112d,
        cu-white: #efefef,
        cu-black: (
                base: #191919,
                light: lighten(#191919, 15%),
                dark: darken(#fff, 15%),
                trans: transparentize(#191919, 0.5)
        ),
        cu-grey: (
                base: #efefef,
                light: lighten(#efefef, 15%),
                dark: darken(#efefef, 15%),
                trans: transparentize(#efefef, 0.5)
        ),
        // brands
        facebook: #3b5998,
        instagram: #517fa4,
        twitter: #00aced,
        vine: #00bf8f,
        linkedin: #007bb5,
        pinterest: #cb2027
);

// Project breakpoints for Include Media
$breakpoints: (
  'small':  414px,
  'medium': 768px,
  'large':  1080px,
  'xlarge': 1440px,
  'sidebardrop': 850px
) !default;

// html
$root-background-color: color(cu-black, base);
//$root-background-image:  '#{$path-images}/body-background-image.png'; //url
$root-background-image:  'https://cdn.shopify.com/s/files/1/0133/8422/t/7/assets/body-background-image.png?2928102653014058577'; //url
// Fonts and type
$root-color: color(cu-black, base);
//$root-font-family: 'Alegreya Sans', sans-serif;
//$root-font-secondary:;
$monospace-font-family: monospace;
$root-font-size: 100%;
$root-line-height: 1.5rem;
$root-link-color: color(primary, base);
$anchor-text-decoration: none;
$selection-background-color: #b3d4fc;
$selection-color: #fff;
$small-font-size: 75%;


// Tables
$table-border-collapse: collapse;
$table-border-spacing: 0;

// Vendor styles
$remodal: true;
$flickity: true;
