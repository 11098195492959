
.icon {
  background-repeat: no-repeat;
}

.icon--sharing {
  background: svg($path-svgs+"sharing.svg", "[fill]: white") no-repeat center;
  background-size: 20px;
}

.icon--facebook {
  background-image: svg($path-svgs+"facebook.svg", "[fill]: white");
}

.icon--twitter {
  background-image: svg($path-svgs+"twitter.svg", "[fill]: white");
}

.icon--instagram {
  background-image: svg($path-svgs+"instagram.svg", "[fill]: white");
}

.icon--gplus{
  background-image: svg($path-svgs+"gplus.svg", "[fill]: white");
}

.icon--vine{
  background-image: svg($path-svgs+"vine.svg", "[fill]: white");
}

.icon--search {
  background-image: svg($path-svgs+"search.svg", "[fill]: white");
}

.icon--cart {
  background-image: svg($path-svgs+"cart.svg", "[fill]: white");
}

.icon--menu {
  background-image: svg($path-svgs+"menu.svg", "[fill]: white");
}

.icon--ellipsis {
  background-image: svg($path-svgs+"ellipsis.svg", "[fill]: white");
}

//@import "sharing";

.icon--quick-links {
  //background: svg($path-svgs+"sharing.svg", "[fill]: white") no-repeat center;
  //background-size: 20px;
  //width: 25px;
  //height: 40px;
  //flex: 0.5;
}

//@import "play";