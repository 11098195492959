.footer-soc-news {
  padding: 20px;

  & h2 {
    color: #c9c9c9;
    text-transform: uppercase;
  }

  & ul {
    padding: 0;
    margin: 0;
  }

  @include media('>=1000px') {
    padding: 0 60px;
  }
}

div.footer_social {
  display: none;

  @include media(">=1000px") {
    display: inline-block;
  }
}

.footer_social__icon {
  display: inline-block;
  text-indent: -999999px;
  width: 70px;
  height: 80px;
}
.signup-form__input {
  width: 60%;
  line-height: 2rem;

  @include media(">=medium") {
    width: 60%;
    line-height: 2rem;
  }

  @include media(">=large") {
    width: 75%;
    line-height: 2rem;
  }
}