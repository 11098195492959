/* www.menucool.com/tooltip/javascript-tooltip */

/*For tooltip target element that has set class="tooltip" */
.tooltip { }

/*For tooltip box*/
#mcTooltip
{
  line-height:16px;
  border: 1px solid #333;
  color:#fff;
  padding:10px 20px;
  border-radius:5px;
  background-color:#222;
}

#mcTooltip, #mcttCo b {
  box-shadow: 0 1px 4px rgba(0,0,0,0.5);
}

/* For hyperlink within tooltip */
/* #mcTooltip a { color:#36c; } */

/*Close button. Available when sticky or overlay has been set to true, or is on touch-enabled devices. */
#mcttCloseButton
{
  width:18px;
  height:18px;
  margin-right:4px;
  margin-top:4px;
}
/*Close button X symbol*/
#mcttCloseButton:after {
  font:normal 38px/18px arial, sans-serif;
  color:white;
  top:0;left:-2px;
}

/*Give the close button a bigger size on smaller devices*/
@media only screen and (max-width: 736px) {
  #mcttCloseButton {
    width:28px;
    height:28px;
  }
  #mcttCloseButton:after {
    font-size:48px;
    line-height:28px;
  }
}

/* Useful when overlay has been set to true or 1 in JS */
#mcOverlay
{
  background-color: rgba(0,0,0,0.5);
  display:none;top:0;left:0;width:100%;height: 100%;overflow:visible;z-index:4;
}
#mcTooltip img {border:0;vertical-align:middle;}

/*The settings below should remain unchanged*/
#mcTooltipWrapper {position:absolute;visibility:hidden;overflow:visible;z-index:9999999999;top:-2000px;}
#mcTooltip {float:left;border-style:solid;position:relative;overflow:hidden;}
.mcTooltipInner {float:left;position:relative;width:auto;height:auto;}
#mcttCo,  #mcttCo b{position:absolute;overflow:hidden;}

/*compatible with bootstrap*/
#mcTooltip, #mcTooltip div {box-sizing:content-box;}

.tip {
  display: none;
}

.tip-share-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &:before {
    content: 'Share on:';
    color: color(cu-grey, dark);
    font-size: 14px
  }
}

.tip-share-list__item {
  line-height: 2.1rem;
}

.tip-share-list__item a {
  color: color(cu-grey, light);
  background-repeat: no-repeat;
  background-position: 3px 2px;
  background-size: 16px;
  padding: 2px 5px 2px 25px;

  &.icon--facebook:hover {
    background-color: map-get($colors, facebook)
  }

  &.icon--twitter:hover {
    background-color: map-get($colors, twitter)
  }
}
